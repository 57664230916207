<template>
  <div>
    <div>
      <!-- top cards -->
      <div class="vx-row customer-single-cards">
        <!-- Number of Employees -->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="UsersIcon"
            smallTitle="Number of Employees"
            :totalValue="numberOfEmployees"
            bgcolor="bg-first-gradient"
            maintextcolor="text-first"
            bgimg="users.png"
          >
          </single-card>
        </div>
        <!-- Revenue -->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="UsersIcon"
            smallTitle="Revenue"
            :totalValue="revenueValue"
            textcolor="text-danger"
            bgcolor="bg-second-gradient"
            maintextcolor="text-second"
            bgimg="users.png"
          >
          </single-card>
        </div>
        <!-- Ownership-->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="ListIcon"
            smallTitle="Ownership"
            :totalValue="ownershipValue"
            textcolor="text-danger"
            bgcolor="bg-third-gradient"
            maintextcolor="text-third"
            bgimg="list.png"
          >
          </single-card>
        </div>
        <!-- Primary Industry -->
        <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
          <single-card
            icon="EyeIcon"
            smallTitle="Primary Industry"
            :totalValue="primaryIndustryValue"
            textcolor="text-danger"
            bgcolor="bg-fourth-gradient"
            maintextcolor="text-fourth"
            bgimg="eye.png"
          >
          </single-card>
        </div>
      </div>
      <!-- chart-table row -->
      <div class="vx-row">
        <!-- Distribution By Total Employee charts -->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card>
            <div class="card-title more-card-title">
              <h2>Distribution By Total Employee</h2>
            </div>
            <vue-apex-charts
              type="bar"
              height="350"
              :options="distributionByTotalEmployeeChart.chartOptions"
              :series="distributionByTotalEmployeeChart.series"
            ></vue-apex-charts>
          </vx-card>
        </div>
        <!-- Distribution By Industry-->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card class="height-100">
            <div class="card-title more-card-title">
              <h2>Distribution By Industry</h2>
            </div>
            <div class="common-table pt-0">
              <!-- table -->
              <vs-table :data="distributionByindustry">
                <template slot="thead">
                  <vs-th>SIC Descriptions</vs-th>
                  <vs-th sort-key="name">% Of Total</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].SIC_Description">
                      {{ data[indextr].SIC_Description }}
                    </vs-td>
                    <vs-td :data="data[indextr].Percent">
                      {{ data[indextr].Percent + "%" }}
                    </vs-td>
                  </vs-tr>
                  <tfoot class="vs-table--tfoot">
                    <tr class="tr-values tr-table-state-null selected">
                      <td class="td vs-table--td">Total</td>
                      <td class="td vs-table--td">
                        {{ totalpercentage + "%" }}
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
      </div>
      <!-- chart-table row -->
      <div class="vx-row">
        <!-- Company Ownership charts -->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card>
            <div class="card-title more-card-title">
              <h2>Company Ownership</h2>
            </div>
            <vue-apex-charts
              ref="ownerShipChart"
              type="pie"
              height="360"
              :options="pieChart.chartOptions"
              :series="pieChart.series"
            ></vue-apex-charts>
          </vx-card>
        </div>
        <!-- Distribution By Annual Sales charts -->
        <div class="vx-col lg:w-1/2 col-cus-12 mb-8 w-full">
          <vx-card>
            <div class="card-title more-card-title">
              <h2>Distribution By Annual Sales</h2>
            </div>
            <vue-apex-charts
              type="bar"
              height="350"
              :options="distributionByAnnualSale.chartOptions"
              :series="distributionByAnnualSale.series"
            ></vue-apex-charts>
          </vx-card>
        </div>
      </div>
      <!-- chart-table row -->
      <div class="vx-row">
        <!-- Distribution By Total Employee charts -->
        <div class="vx-col xl:w-full col-cus-12 mb-8 w-full">
          <vx-card>
            <div class="card-title card-title-padding-200 more-card-title flex align-center justify-between">
              <div><h2>My Customers</h2></div>
              <div>
                <vs-button
                  title="Update"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-download"
                  class="mr-2 float-left"
                  @click="exportCustomer()"
                  >{{ LabelConstant.buttonLabelExport }}
                </vs-button>
              </div>
            </div>
            <div class="common-table my-customers pt-0">
              <!-- table -->
              <vs-table
                v-model="selectedMain"
                :data="customers"
                pagination
                :max-items="itemsPerPage"
                multiple
                search
              >
                <template slot="thead">
                  <vs-th>Logo</vs-th>
                  <vs-th>Company Name</vs-th>
                  <vs-th>Location</vs-th>
                  <vs-th>Employee Count</vs-th>
                  <vs-th>Revenue</vs-th>
                  <vs-th>Industry</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      <img
                        :src="data[indextr].Logo"
                        alt="Company Logo"
                        height="50"
                        width="50"
                        class="inline-block mr-2"
                      />
                    </vs-td>
                    <vs-td :data="data[indextr].CompanyName">
                      {{ data[indextr].CompanyName }}
                    </vs-td>
                    <vs-td :data="data[indextr].location">
                      {{ data[indextr].location }}
                    </vs-td>
                    <vs-td :data="data[indextr].Employees">
                      {{ data[indextr].Employees }}
                    </vs-td>
                    <vs-td :data="data[indextr].RevenueRange">
                      {{ data[indextr].RevenueRange }}
                    </vs-td>
                    <vs-td :data="data[indextr].Industry">
                      {{ data[indextr].Industry }}
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
              <!-- Total Customer -->
              <p class="table-data-entries-details">
                Total Customers:
                <span>{{ totalCustomers | tableRecordNumberFormatter }}</span>
              </p>
            </div>
          </vx-card>
        </div>
      </div>
    </div>

    <!-- Select Customer warning for export Customer -->
    <find-any-one-select-contact-add-to-saved-warning-modal
      :popupTitle="'Download Customers'"
      :isPopup="selecteCustomersWarningForExportCustomersPopup"
      :selectContactWarningMessage="'Please select customers to export.'"
      @clickForFinish="selecteCustomersWarningForExportCustomersPopup = false"
    ></find-any-one-select-contact-add-to-saved-warning-modal>
    
    <!-- Download Excel Customer -->
    <find-any-one-download-final
      :popupTitle="'Download Customer'"
      :isPopup="downloadExcelCustomerPopup"
      :sheetOptions="downloadCustomer"
      :excelFileName="'Customer.xlsx'"
      :excelOptions="{bookType: 'xlsx'}"
      :csvFileName="'Customer.csv'"
      :csvOptions="{bookType: 'csv'}"
      :whichOptionShow="true"
      :showPushtoCRM="false"
      @clickForFinish="(downloadExcelCustomerPopup = false, selectedMain = [])"
    ></find-any-one-download-final>
  </div>
</template>
 
<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../components/filter/SelectFilter";
import DateRangePicker from "../../components/filter/DateRangePicker";
import SingleCard from "../../components/SingleCard";
/* chart */
import VueApexCharts from "vue-apexcharts";
import FindAnyOneSelectContactAddToSavedWarningModal from '../../components/modal/FindAnyOneSelectContactAddToSavedWarningModal.vue'
import FindAnyOneDownloadFinal from '../../components/modal/FindAnyOneDownloadFinal.vue'

export default {
  components: {
    SelectFilter,
    SingleCard,
    VueApexCharts,
    DateRangePicker,
    VxCard,
    FindAnyOneSelectContactAddToSavedWarningModal,
    FindAnyOneDownloadFinal
  },
  data: () => ({
    selecteCustomersWarningForExportCustomersPopup: false,
    downloadExcelCustomerPopup: false,
    downloadCustomer: null,
    openExportContactOptionsPopup: false,
    revenueValue: "",
    primaryIndustryValue: "",
    ownershipValue: "",
    numberOfEmployees: 0,
    totalCustomers: 0,
    selectedMain: [],
    distributionByindustry: [],
    totalpercentage: 0,
    itemsPerPage: 10,
    customers: [],
    distributionByTotalEmployeeChart: {
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: "#044d80",
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#888"],
          },
          formatter: function (val) {
            return val + "%";
          },
          offsetX: 40,
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
    },
    //   Distribution By Annual Sales charts
    distributionByAnnualSale: {
      series: [
        {
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: "#044d80",
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ["#888"],
          },
          formatter: function (val) {
            return val + "%";
          },
          offsetX: 40,
        },
        xaxis: {
          categories: [],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      },
    },
    // Company Ownership
    pieChart: {
      series: [],
      chartOptions: {
        labels: [],
        colors: ["#044d80", "#28C76F"],
        legend: {
          position: "top",
        },
      },
    },
  }),
  created() {
    this.getCustomers();
    this.getDistributionByIndustry();
    this.getDistributionByAnnualSales();
    this.getOwnershipChart();
    this.getDistributionByTotalEmployees();
    this.getNumberOfEmployeesCount();
    this.getPrimaryIndustry();
    this.getOwnership();
    this.getRevenue();
  },
  methods: {
    //get customers
    async getCustomers() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getMyCustomers")
        .then((response) => {
          let data = response.data;
          this.customers = data;
          let that = this;
          this.customers.map(function (manipulatedData) {
            manipulatedData.location = that.beautifyAddress(manipulatedData);
            return manipulatedData;
          });
          this.totalCustomers = this.customers.length;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // get distribution by industry
    async getDistributionByIndustry() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getDistributionByIndustry")
        .then((response) => {
          let data = response.data;
          this.distributionByindustry = data;
          let per = 0;
          data.forEach((e) => {
            per += e.Percent;
          });
          this.totalpercentage = per;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // bar chart data for distribution by annual sales
    async getDistributionByAnnualSales() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getDistributionByAnnualSales")
        .then((response) => {
          let data = response.data;
          let x = [];
          let firstGroup = [];
          data.forEach((element) => {
            x.push(element.Name);
            firstGroup.push(element.Value);
          });
          this.distributionByAnnualSale.chartOptions = {
            ...this.distributionByAnnualSale.chartOptions,
            ...{
              xaxis: {
                categories: x,
              },
            },
          };
          this.distributionByAnnualSale.series = [
            {
              name: "Annual Sales",
              data: firstGroup,
            },
          ];
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    //pie chart ownership chart
    async getOwnershipChart() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getOwnershipChart")
        .then((response) => {
          let data = response.data;
          let x = [];
          let firstGroup = [];
          data.forEach((element) => {
            x.push(element.x);
            firstGroup.push(element.FirstGroup);
          });
          this.pieChart.chartOptions = {
            ...this.pieChart.chartOptions,
            ...{
              labels: x,
            },
          };
          this.pieChart.series = firstGroup;
          this.$refs.ownerShipChart.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // bar chart data for distribution by total employees
    async getDistributionByTotalEmployees() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getDistributionByTotalEmployees")
        .then((response) => {
          let data = response.data;
          let x = [];
          let firstGroup = [];
          data.forEach((element) => {
            x.push(element.Name);
            firstGroup.push(element.Value);
          });
          this.distributionByTotalEmployeeChart.chartOptions = {
            ...this.distributionByTotalEmployeeChart.chartOptions,
            ...{
              xaxis: {
                categories: x,
              },
            },
          };
          this.distributionByTotalEmployeeChart.series = [
            {
              name: "Total Employees",
              data: firstGroup,
            },
          ];
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // get employee count api
    async getNumberOfEmployeesCount() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getNumberofEmployees")
        .then((response) => {
          let data = response.data;
          this.numberOfEmployees = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // get owner data
    async getOwnership() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getOwnership")
        .then((response) => {
          let data = response.data;
          this.ownershipValue = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // get primary industry value
    async getPrimaryIndustry() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getPrimaryIndustry")
        .then((response) => {
          let data = response.data;
          this.primaryIndustryValue = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // get revenue range value
    async getRevenue() {
      this.$vs.loading();
      await this.axios
        .get("ws/FindAnyoneMyCustomerProfile/getRevenue")
        .then((response) => {
          let data = response.data;
          this.revenueValue = data;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.response.data.Errors[0].Message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
            time: 4000,
          });
          this.showError(e);
        });
    },
    // export locally
    exportCustomer() {
      if (!this.selectedMain.length > 0) {
        this.selecteCustomersWarningForExportCustomersPopup = true;
      } else {
        this.downloadExcelCustomerPopup = true;
        let arrObj = [];
        for (let i = 0; i < this.selectedMain.length; i++) {
          arrObj.push({
            CompanyId: this.selectedMain[i].CompanyId,
            Logo: this.selectedMain[i].Logo,
            CompanyName: this.selectedMain[i].CompanyName,
            RevenueRange: this.selectedMain[i].RevenueRange,
            Industry: this.selectedMain[i].Industry,
            Score: this.selectedMain[i].Score,
            Url: this.selectedMain[i].Url,
            City: this.selectedMain[i].City,
            State: this.selectedMain[i].State,
            Country: this.selectedMain[i].Country,
          });
        }
        this.downloadCustomer = [{ name: "Customers", data: arrObj }];
      }
    },
    // beautify address
    beautifyAddress(value) {
      let contactAddressArr = [];
      contactAddressArr.push(value.City);
      contactAddressArr.push(value.State);
      contactAddressArr.push(value.Country);
      let contactAddressFinal = "";
      for (let i = 0; i < contactAddressArr.length; i++) {
        if (contactAddressArr[i] && contactAddressArr[i].length > 0) {
          contactAddressFinal += contactAddressArr[i] + ", ";
        }
      }
      return contactAddressFinal.substring(0, contactAddressFinal.length - 2);
    },
  },
};
</script>


